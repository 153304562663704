<template>
  <div class="hosting-service-config-container">
    <ct-centered-spinner v-if="loading" class="loading-spinner">
      <p class="loading-text">
        {{ text.savingMessage }}
      </p>
    </ct-centered-spinner>
    <div
      v-if="hostingTransferInfo || standaloneHostingInfo"
      class="hosting-transfer-info-section"
    >
      <hosting-transfer-status
        v-if="hostingTransferInfo"
        :pending-hosting-info="hostingTransferInfo"
        :bus="bus"
      />
      <standalone-hosting-status
        v-if="standaloneHostingInfo"
        :pending-hosting-info="standaloneHostingInfo"
        :bus="bus"
      />
      <div
        v-if="showPrimaryNavButton"
        class="slide-viewer-button-row primary-button-container"
      >
        <primary-button
          v-if="hostingTransferInfo || standaloneHostingVerified"
          class="button-padding"
          :button-text="navigationButtonText"
          :disabled="loading"
          :aria-label="navigationButtonText + ' button'"
          @onclick="navigateFromHostingTransferInfo()"
        />
      </div>
    </div>
    <div v-else>
      <primary-button
        class="website-page-nav-button button-padding"
        :button-text="text.navButton.websitePagePrompt"
        :disabled="false"
        :aria-label="text.navButton.websitePagePrompt + ' button'"
        @onclick="navigateToWebsitesPage()"
      />
      <transfer-service-section
        :service-type="'hosting'"
        @show-transfer-modal="showTransferModal"
      />
      <hosting-transfer-modal v-model="showHostingTransferModal" :bus="bus" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { logDomainInteraction } from '@/components/StagelineV2/slides/serviceConfiguration/services/helper'

export default {
  name: 'HostingServiceConfiguration',
  components: {
    PrimaryButton:          () => import('@/components/StagelineV2/shared/PrimaryButton'),
    CtCenteredSpinner:      () => import('@/components/shared/CtCenteredSpinner'),
    HostingTransferModal:   () => import('@/components/Services/Hosting/HostingTransferModal'),
    HostingTransferStatus:  () => import('@/components/Services/Hosting/HostingTransferStatus'),
    StandaloneHostingStatus:() => import('@/components/Services/Hosting/StandaloneHostingStatus.vue'),
    TransferServiceSection: () => import('@/components/Services/shared/TransferServiceSection'),

  },
  mixins: [makeToastMixin],
  props: {
    slide: null,
  },
  data() {
    return {
      bus: new Vue(),
      loading: false,
      text: {
        navButton: {
          nextSlide: 'Continue',
          websitePage: 'Go to Websites',
          websitePagePrompt: 'Start My Website From Scratch',
        },
        transferPrompt: 'Transfer My Website',
        savingMessage: 'Creating hosting...this can take a few minutes. Please do not refresh the page.',
        successMessage: 'Hosting has been created.',
        errorMessage: 'Unable to create web hosting.',
      },
      showHostingTransferModal: false,
      showPrimaryNavButton: true,
      slideStartTime: null,
    }
  },

  computed: {
    ...mapGetters('vouchers', [
      'unRedeemedVoucherByProductCategory',
    ]),
    ...mapGetters('domains', [
      'hostingTransferInfo',
      'standaloneHostingInfo',
    ]),
    ...mapGetters('stageline', [
      'currentStage',
    ]),
    serviceType() {
      return this.slide.layout_sub_type
    },
    navigationButtonText() {
      if (this.nameserversVerified || this.standaloneHostingVerified) {
        return this.text.navButton.nextSlide
      }

      return this.text.navButton.websitePage
    },
    nameserversVerified() {
      return this.pendingHostingInfo?.nameservers?.status === "internal"
    },
    standaloneHostingVerified() {
      return this.standaloneHostingInfo?.status === "completed-standalone-hosting"
    },
  },

  async mounted() {
    this.createClientSlideInteraction()

    this.bus.$on('log-domain-interaction', await logDomainInteraction)
  },

  methods: {
    ...mapActions('stageline', [
      'completeStageForRedirect',
    ]),
    createClientSlideInteraction() {
      if (this.ghostMode) return

      this.slideStartTime = Date.now()
    },
    async completeSlideInteraction() {
      if(!this.slideStartTime) return

      const logInfo = {
        name: 'slide_interaction',
        slideStartTime: this.slideStartTime,
      }

      await logDomainInteraction(logInfo)
    },
    async showTransferModal() {
      this.showHostingTransferModal = true

      await this.completeSlideInteraction()
      await logDomainInteraction({ name: 'hosting-transfer-click' })
    },
    navigateToNextSlide() {
      this.$emit('next-slide')
    },
    async navigateToWebsitesPage() {
      await this.completeSlideInteraction('slide_interaction')
      await this.completeStageForRedirect(this.currentStage.id)
      await this.$router.push({ name: 'websites' }).catch(() => {})
    },
    async navigateFromHostingTransferInfo() {
      this.loading = true

      this.hostingTransferInfo && this.nameserversVerified ?
        this.navigateToNextSlide() :
        await this.navigateToWebsitesPage()

      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
.hosting-service-config-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .hosting-transfer-info-section {
    width: 100%;

    .primary-button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
    }
  }
  .website-page-nav-button {
    margin-top: 2.5em;
  }
  .button-padding {
    padding-right: 1em;
    padding-left: 1em;
  }
}
</style>
